import React from 'react';
import './Header.css';

function Header({ nextPackage, onOpen }) {
  return (
    <header>
      <h1>Olá, Vamos iniciar?</h1>
      <div className="next-package">
        <input type="text" value={nextPackage} readOnly />
        <button onClick={onOpen}>Abrir</button>
      </div>
    </header>
  );
}

export default Header;
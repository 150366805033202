import React from 'react';
import './Footer.css';

function Footer({ isPackageOpen, selectedFile, onNewFile, onDownloadFile, onDeleteFile, onClosePackage, onExit }) {
  return (
    <footer>
      {isPackageOpen ? (
        <>
          <button disabled={!selectedFile} onClick={onDownloadFile}>Baixar</button>
          <button onClick={onNewFile}>Novo</button>
          <button disabled={!selectedFile} onClick={onDeleteFile}>Apagar</button>
          <button onClick={onClosePackage}>Finalizar Pacote</button>
        </>
      ) : null}
      <button onClick={onExit}>Sair</button>
    </footer>
  );
}

export default Footer;
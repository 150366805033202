import React, { useState, useRef, useEffect } from 'react';
import Header from './components/Header';
import PackageList from './components/PackageList';
import FileList from './components/FileList';
import Footer from './components/Footer';
import Login from './components/Login';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userLevel, setUserLevel] = useState(null);
  const [isPackageOpen, setIsPackageOpen] = useState(false);
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [showUploadSection, setShowUploadSection] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showCloseConfirm, setShowCloseConfirm] = useState(false);
  const fileInputRef = useRef(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  // Função para buscar a lista de pacotes
  const fetchPackages = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/packages`, { withCredentials: true });
      const packagesData = response.data;
      console.log('Pacotes recebidos:', packagesData);
      setPackages(packagesData);

      const openPackage = packagesData.find(pkg => pkg.is_open);
      if (openPackage) {
        setSelectedPackage(openPackage);
        setIsPackageOpen(true); // Altera a tela para `FileList` se houver um pacote aberto
      } else {
        setIsPackageOpen(false); // Mantém `PackageList` se não houver pacote aberto
      }
    } catch (error) {
      console.error('Erro ao buscar pacotes:', error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setIsAuthenticated(true);
      fetchUserLevel();
      fetchPackages();
      if (isAuthenticated) {
        fetchPackages();
      }
    }
  }, [isAuthenticated]);

  // Função para buscar o nível do usuário logado
  const fetchUserLevel = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/user`, { withCredentials: true });
      setUserLevel(response.data.user_level_id); // Ajuste aqui
    } catch (error) {
      console.error('Erro ao buscar nível do usuário:', error);
    }
  };

  const handleLogin = (userData) => {
    setIsAuthenticated(true);
    setUserLevel(userData.level);
    fetchPackages();
  };

  const openNextPackage = async () => {
    if (isPackageOpen) {
      alert('Já existe um pacote aberto!');
      return;
    }

    const newPackageName = `#${packages.length + 1} - ${new Date().toLocaleDateString()}`;
    try {
      const response = await axios.post(`${apiUrl}/api/packages`, { name: newPackageName });
      setSelectedPackage(response.data);
      setFiles([]);
      setIsPackageOpen(true);
    } catch (error) {
      console.error('Erro ao abrir novo pacote:', error);
    }
  };

  const handleSelectPackage = async (pkg) => {
    try {
      const response = await axios.get(`${apiUrl}/api/packages/${pkg.id}`);
      setSelectedPackage(response.data); // Deve incluir os arquivos no campo "files"
      setIsPackageOpen(true);
    } catch (error) {
      console.error('Erro ao buscar detalhes do pacote:', error);
    }
  };

  const closePackage = async () => {
    if (!selectedPackage) return;
    try {
      // Fechar o pacote via API
      await axios.put(`${apiUrl}/api/packages/${selectedPackage.id}/close`, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
  
      // Limpar pacotes e arquivos da UI
      setSelectedPackage(null);
      setFiles([]);
      setIsPackageOpen(false);
  
      // Recarregar a lista de pacotes
      fetchPackages();
    } catch (error) {
      console.error('Erro ao finalizar o pacote:', error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setUserLevel(null);
  };

  const handleDelete = async () => {
    if (!selectedFile) return;

    try {
      await axios.delete(`${apiUrl}/api/files/${selectedFile.id}`);
      const updatedFiles = files.filter(file => file.id !== selectedFile.id);
      setFiles(updatedFiles);
      setSelectedFile(null);
    } catch (error) {
      console.error('Erro ao deletar o arquivo:', error);
    }
  };

  const handleUpload = async (file, name) => {
    if (!selectedPackage) return;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', name);

    try {
      const response = await axios.post(
        `/api/packages/${selectedPackage.id}/files`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      setFiles(prevFiles => [...prevFiles, response.data]);
    } catch (error) {
      console.error('Erro ao fazer upload:', error);
    }
  };

  const handleDeleteConfirm = () => {
    setShowDeleteConfirm(false);
    handleDelete();
  };

  const handleCloseConfirm = () => {
    setShowCloseConfirm(false);
    closePackage();
  };

  const handleDownload = async () => {
    if (!selectedFile) {
      alert('Selecione um arquivo para baixar.');
      return;
    }

    try {
      const response = await axios.get(
        `${apiUrl}/api/files/${selectedFile.id}/download`,
        {
          responseType: 'blob',
          withCredentials: true,
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', selectedFile.name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Erro ao baixar o arquivo:', error);
      alert('Erro ao baixar o arquivo.');
    }
  };

  const toggleUploadSection = () => {
    setShowUploadSection(prev => !prev);
  };

  return (
    <div className="app">
      {isAuthenticated ? (
        <>
          <Header
            isPackageList={!isPackageOpen}
            packages={packages}
            nextPackage={`#${packages.length + 1} - ${new Date().toLocaleDateString()}`}
            onOpen={openNextPackage}
            selectedPackage={selectedPackage}
            onClosePackage={() => setShowCloseConfirm(true)}
            userLevel={userLevel}
          />
          {isPackageOpen ? (
            <FileList
              selectedPackage={selectedPackage}
              files={files}
              setFiles={setFiles}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              showUploadSection={showUploadSection}
              toggleUploadSection={toggleUploadSection}
              fileInputRef={fileInputRef}
              userLevel={userLevel} // Passa nível do usuário
            />
          ) : (
            <PackageList
              packages={packages}
              onSelectPackage={handleSelectPackage}
            />
          )}
          <Footer
            isPackageOpen={isPackageOpen}
            selectedFile={selectedFile}
            onNew={toggleUploadSection}
            onDownloadFile={handleDownload}
            onDeleteFile={() => setShowDeleteConfirm(true)}
            onExit={handleLogout}
            fileInputRef={fileInputRef}
          />
          <Modal show={showDeleteConfirm} onHide={() => setShowDeleteConfirm(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmar Exclusão</Modal.Title>
            </Modal.Header>
            <Modal.Body>Tem certeza que deseja APAGAR permanentemente o arquivo?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDeleteConfirm(false)}>
                Cancelar
              </Button>
              <Button variant="danger" onClick={handleDeleteConfirm}>
                Apagar
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={showCloseConfirm} onHide={() => setShowCloseConfirm(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmar Fechamento do Pacote</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              O pacote atual será arquivado e um novo pacote vazio será aberto. <br />
              Confirma o FECHAMENTO deste pacote?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowCloseConfirm(false)}>
                Cancelar
              </Button>
              <Button variant="warning" onClick={handleCloseConfirm}>
                Finalizar
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </div>
  );
}

export default App;
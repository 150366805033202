import React from 'react';

function PackageList({ packages, onSelectPackage }) {
  return (
    <div className="package-list">
      <h2>Pacotes Fechados</h2>
      <ul>
        {packages
          .filter(pkg => !pkg.isOpen) // Mostra apenas pacotes fechados
          .map(pkg => (
            <li key={pkg.id} onClick={() => onSelectPackage(pkg)}>
              {pkg.name}
            </li>
          ))}
      </ul>
    </div>
  );
}

export default PackageList;
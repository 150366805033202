import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClock, faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import './PackageList.css';

const apiUrl = process.env.REACT_APP_API_URL;

function FileList({ selectedPackage, files, setFiles, selectedFile, setSelectedFile, fileInputRef }) {
  const [isLoading, setIsLoading] = useState(false);

  const formatOpenDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    const formattedTime = date.toLocaleTimeString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit',
    });
    return `${formattedDate} ${formattedTime}`;
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Verificar se já existe um arquivo com o mesmo nome no frontend
    const fileExists = files.some((f) => f.name === file.name);
    if (fileExists) {
        alert('Já existe um arquivo com este nome na listagem.');
        return;
    }

    const newFile = {
        id: Date.now(), // Gerar um ID temporário
        name: file.name,
        created_at: new Date().toISOString(),
        status: 'new', // Status inicial
    };

    // Adiciona temporariamente o arquivo com status "new" no topo da lista
    setFiles((prevFiles) => [newFile, ...prevFiles]);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', file.name);

    setIsLoading(true);

    try {
        const response = await axios.post(`${apiUrl}/api/packages/${selectedPackage.id}/files`, formData, {
            withCredentials: true,
        });

        const uploadedFile = { ...response.data, status: 'complete' };

        setFiles((prevFiles) =>
            prevFiles.map((f) =>
                f.id === newFile.id ? uploadedFile : f
            )
        );

        setSelectedFile(uploadedFile);
    } catch (error) {
        if (error.response?.status === 409) {
            alert('Erro: Já existe um arquivo com este nome no pacote.');
        } else {
            console.error('Erro ao fazer upload:', error);
            alert('Erro ao fazer upload do arquivo.');
        }

        // Remove o arquivo temporário em caso de erro
        setFiles((prevFiles) => prevFiles.filter((f) => f.id !== newFile.id));
    } finally {
        setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedPackage && selectedPackage.id && files.length === 0) {
      setIsLoading(true);
      axios
        .get(`${apiUrl}/api/packages/${selectedPackage.id}/files`, { withCredentials: true })
        .then((response) => {
          const filesWithStatus = response.data.map((file) => ({
            ...file,
            status: 'uploaded', // Marca todos os arquivos carregados como existentes
          }));
          setFiles(filesWithStatus);
        })
        .catch((error) => {
          console.error('Erro ao buscar arquivos do pacote:', error);
        })
        .finally(() => setIsLoading(false));
    }
  }, [selectedPackage, setFiles, files.length]);

  const handleDownload = async () => {
    if (!selectedFile) {
      alert('Selecione um arquivo para baixar.');
      return;
    }

    try {
      const response = await axios.get(`${apiUrl}/${selectedFile.path}`, {
        responseType: 'blob',
        withCredentials: true,
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', selectedFile.name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Erro ao baixar o arquivo:', error);
      alert('Erro ao baixar o arquivo');
    }
  };

  return (
    <div className="file-list">

        {isLoading && (
            <div className="loading-overlay">
                <div className="spinner"></div>
                <p>Carregando...</p>
            </div>
        )}

      <h2>Conteúdo</h2>
      <h3>Relação de arquivos</h3>

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />

      <ul>
        {files.length > 0 ? (
          files.map((file, index) => (
            <li
              key={file.id}
              className={selectedFile && selectedFile.id === file.id ? 'selected' : ''}
              onClick={() => setSelectedFile(file)}
              style={{
                cursor: 'pointer',
                backgroundColor: selectedFile && selectedFile.id === file.id ? '#add8e6' : 'transparent',
              }}
            >
              <span>{files.length - index} - </span> {file.name}
              {file.status === 'uploaded' && <FontAwesomeIcon icon={faCheckDouble} style={{ color: 'dodgerblue', marginLeft: '8px' }} />}
              {file.status === 'new' && <FontAwesomeIcon icon={faClock} style={{ color: 'orange', marginLeft: '8px' }} />}
              {file.status === 'complete' && (
                <>
                  <FontAwesomeIcon icon={faCheck} style={{ color: 'lightgray', marginLeft: '8px' }} />
                </>
              )}
              - ({formatOpenDate(file.created_at)})
            </li>
          ))
        ) : (
          <p>Nenhum arquivo disponível.</p>
        )}
      </ul>
    </div>
  );
}

export default FileList;
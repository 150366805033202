import React, { useState } from 'react';
import './Login.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube, faSignIn } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const response = await axios.post(`${apiUrl}/api/login`, {
        email,
        password,
      });

      const token = response.data.access_token;
      localStorage.setItem('token', token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      onLogin();
    } catch (error) {
      setError('Email ou senha incorretos');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <button className="logo-button">
        <FontAwesomeIcon icon={faCube} className="logo-icon" />
      </button>
      <h2>Acesso ao FileBox</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleLogin} className="login-form">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="login-input"
        />
        <input
          type="password"
          placeholder="Senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="login-input"
        />
        <button
          type="submit"
          className="login-button"
          disabled={loading}
        >
          {loading ? (
            <span>Carregando...</span>
          ) : (
            <>
              <FontAwesomeIcon icon={faSignIn} size="lg" />
              &nbsp;&nbsp;Entrar
            </>
          )}
        </button>
      </form>
      <footer className="footer-version">
        www.vianovabeneficios.com.br | 2024 | V 1.1.0
      </footer>
    </div>
  );
};

export default Login;
import React from 'react';
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube, faFolder, faFolderOpen } from '@fortawesome/free-solid-svg-icons';

function Header({
  isPackageList,
  packages,
  nextPackage,
  onOpen,
  selectedPackage,
  onClosePackage,
  userLevel, // Nível do usuário adicionado
}) {
  const formatOpenDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  const filteredPackages = packages.filter((pkg) => {
    const packageDate = new Date(pkg.created_at);
    return (
      packageDate.getMonth() === currentMonth &&
      packageDate.getFullYear() === currentYear
    );
  });

  // Formatação do pacote atual
  const currentPackage = selectedPackage ? (
    <>
      {`Pacote ${selectedPackage?.name.substring(0, 3)}`}
      <br />
      {new Date(selectedPackage?.created_at).toLocaleString('pt-BR', { month: 'long', year: 'numeric' })}
    </>
  ) : (
    'Pacote atual'
  );

  // Número do próximo pacote
  const nextPackageNumber = filteredPackages.length + 1;

  // Formatação do próximo pacote
  nextPackage = `#${nextPackageNumber} - ${new Date().toLocaleString('pt-BR', {
    month: 'long',
    year: 'numeric',
  })}`;

  console.log(userLevel);
  
  return (
    <header className="header">
      {isPackageList ? (
        <div className="header-content">
          <button className="logo-button">
            <FontAwesomeIcon icon={faCube} className="logo-icon" />
          </button>
          <div>
            <h1>Olá, vamos iniciar?</h1>
            <input
              type="text"
              value={nextPackage}
              readOnly
              className="package-name"
            />
            <button onClick={onOpen} className="btn-open">
              <FontAwesomeIcon icon={faFolderOpen} /> Abrir
            </button>
          </div>
          <div>&nbsp;</div>
        </div>
      ) : (
        <div className="header-content">
          <button className="logo-button">
            <FontAwesomeIcon icon={faCube} className="logo-icon" />
          </button>
          <div>
            <h1>{currentPackage}</h1>
            <p>Aberto em {formatOpenDate(selectedPackage?.created_at)}</p>
          </div>
          {userLevel === 1 || userLevel === 2 ? ( // Apenas operadores podem finalizar o pacote
            <button className="close-package-button" onClick={onClosePackage}>
              <FontAwesomeIcon icon={faFolder} />
              <p>Finalizar Pacote</p>
            </button>
          ) : (
            <div>
             &nbsp;
            </div>
          )}
        </div>
      )}
    </header>
  );
}

export default Header;
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload, faCloudDownload, faTrash, faSignOut } from '@fortawesome/free-solid-svg-icons';
import './Footer.css';

function Footer({ isPackageOpen, selectedFile, onDownloadFile, onDeleteFile, onExit, fileInputRef }) {
  return (
    <footer>
      {isPackageOpen ? (
        <>
          <button onClick={() => fileInputRef.current.click()}>
            <FontAwesomeIcon icon={faCloudUpload} size="lg" />
            <p>Novo</p>
          </button>
          <button disabled={!selectedFile} onClick={onDownloadFile}>
            <FontAwesomeIcon icon={faCloudDownload} size="lg" />
            <p>Baixar</p>
          </button>
          <button onClick={onDeleteFile} disabled={!selectedFile}>
            <FontAwesomeIcon icon={faTrash} size="lg" />
            <p>Apagar</p>
          </button>
        </>
      ) : null}
      <button onClick={onExit}>
        <FontAwesomeIcon icon={faSignOut} size="lg" />
        <p>Sair</p>
      </button>
    </footer>
  );
}

export default Footer;
import React, { useState } from 'react';
import axios from 'axios';
import './PackageList.css';

function FileList({ selectedPackage, selectedFile, setSelectedFile, setSelectedPackage }) {
  const [fileToUpload, setFileToUpload] = useState(null);
  const [fileName, setFileName] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;

  if (!selectedPackage) {
    return <p>Carregando pacote...</p>;
  }

  const handleFileChange = (e) => {
    setFileToUpload(e.target.files[0]);
  };

  const handleFileNameChange = (e) => {
    setFileName(e.target.value);
  };

  const handleUpload = async () => {
    if (!fileToUpload || !fileName) {
      alert('Por favor, forneça o nome do arquivo e selecione um arquivo para upload.');
      return;
    }
  
    const formData = new FormData();
    formData.append('file', fileToUpload);
    formData.append('name', fileName);
  
    try {
      const response = await axios.post(`${apiUrl}/api/packages/${selectedPackage.id}/files`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Atualiza a lista de arquivos
      setSelectedPackage({
        ...selectedPackage,
        files: [...(selectedPackage.files || []), response.data],
      });
      
      setFileToUpload(null);
      setFileName('');
    } catch (error) {
      console.error('Erro ao fazer upload:', error);
      alert('Erro ao fazer upload do arquivo');
    }
  };  

  const handleDownload = () => {
    if (!selectedFile) {
      alert('Selecione um arquivo para download.');
      return;
    }

    axios({
      url: selectedFile.path,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const blob = new Blob([response.data]);
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = selectedFile.name;
      link.click();
    });
  };

  const handleDelete = async () => {
    if (!selectedFile) {
      alert('Selecione um arquivo para apagar.');
      return;
    }

    try {
      await axios.delete(`${apiUrl}/api/files/${selectedFile.id}`);
      setSelectedPackage({
        ...selectedPackage,
        files: selectedPackage.files.filter(file => file.id !== selectedFile.id),
      });
      setSelectedFile(null);
    } catch (error) {
      console.error('Erro ao excluir arquivo:', error);
      alert('Erro ao excluir o arquivo');
    }
  };

  return (
    <div className="file-list">
      <h2>{selectedPackage.name}</h2>
      <p>Pacote aberto em: {new Date(selectedPackage.created_at).toLocaleString()}</p>
      <h3>Conteúdo - relação de arquivos</h3>

      <ul>
        {selectedPackage.files && selectedPackage.files.length > 0 ? (
          selectedPackage.files.map(file => (
            <li 
              key={file.id} 
              className={selectedFile && selectedFile.id === file.id ? 'selected' : ''} 
              onClick={() => setSelectedFile(file)}
            >
              {file.name} - {new Date(file.created_at).toLocaleString()}
            </li>
          ))
        ) : (
          <p>Nenhum arquivo encontrado neste pacote.</p>
        )}
      </ul>

      <div>
        <input 
          type="text" 
          placeholder="Nome do arquivo" 
          value={fileName} 
          onChange={handleFileNameChange}
        />
        <input type="file" onChange={handleFileChange} />
      </div>

      <div>
        <button onClick={handleUpload}>Novo (Upload)</button>
        <button onClick={handleDownload} disabled={!selectedFile}>Baixar</button>
        <button onClick={handleDelete} disabled={!selectedFile}>Apagar</button>
      </div>
    </div>
  );
}

export default FileList;
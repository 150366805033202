import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import PackageList from './components/PackageList';
import FileList from './components/FileList';
import Footer from './components/Footer';
import Login from './components/Login';
import axios from 'axios';
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isPackageOpen, setIsPackageOpen] = useState(false);
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setIsAuthenticated(true);
      fetchPackages();
    }
  }, []);

  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchPackages = async () => { 
    try {
      const response = await axios.get(`${apiUrl}/api/packages`);
      setPackages(response.data);
      const openPackage = response.data.find(pkg => pkg.isOpen);
      if (openPackage) {
        setSelectedPackage(openPackage);
        setIsPackageOpen(true);
      }
    } catch (error) {
      console.error('Erro ao buscar pacotes:', error);
    }
  };

  const handleLogin = () => {
    setIsAuthenticated(true);
    fetchPackages();
  };

  const openNextPackage = async () => {
    if (isPackageOpen) {
      alert("Já existe um pacote aberto!");
      return;
    }
    
    const newPackageName = `#${packages.length + 1} - ${new Date().toLocaleDateString()}`;
    try {
      const response = await axios.post(`${apiUrl}/api/packages`, { name: newPackageName });
      setSelectedPackage(response.data);
      setIsPackageOpen(true);
    } catch (error) {
      console.error('Erro ao abrir novo pacote:', error);
    }
  };

  const closePackage = async () => {
    if (!selectedPackage) return;
    try {
      await axios.put(`${apiUrl}/api/packages/${selectedPackage.id}/close`);
      setSelectedPackage(null);
      setIsPackageOpen(false);
      fetchPackages(); // Atualiza a lista de pacotes
    } catch (error) {
      console.error('Erro ao finalizar o pacote:', error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  return (
    <div className="app">
      {isAuthenticated ? (
        <>
          <Header nextPackage={`#${packages.length + 1} - ${new Date().toLocaleDateString()}`} onOpen={openNextPackage} />
          {isPackageOpen ? (
            <FileList 
              selectedPackage={selectedPackage} 
              selectedFile={selectedFile} 
              setSelectedFile={setSelectedFile}
              setSelectedPackage={setSelectedPackage}
            />
          ) : (
            <PackageList packages={packages} onSelectPackage={setSelectedPackage} />
          )}
          <Footer 
            isPackageOpen={isPackageOpen} 
            selectedFile={selectedFile} 
            onNewFile={() => { /* Função para upload */ }} 
            onDownloadFile={() => { /* Função para download */ }} 
            onDeleteFile={() => { /* Função para deletar */ }} 
            onClosePackage={closePackage} 
            onExit={handleLogout}
          />
        </>
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </div>
  );
}

export default App;
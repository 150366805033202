import React from 'react';

function PackageList({ packages, onSelectPackage }) {
  return (
    <div className="package-list">
      <h2>Pacotes Fechados</h2>
      <ul>
        {packages
          .filter(pkg => !pkg.is_open) // Mostra apenas pacotes fechados
          .map((pkg) => (
            <li key={pkg.id} /* onClick={() => onSelectPackage(pkg)} */>
              {pkg.name.substring(0, 3)} - {new Date(pkg.created_at).toLocaleString('pt-BR', { month: 'long', year: 'numeric' })}
            </li>
          ))}
      </ul>
    </div>
  );
}

export default PackageList;